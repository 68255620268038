import React, { useEffect, useRef } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Article, ArticleHeader } from "./layout"
import Video from "./video"
import sizeMe from "react-sizeme"
import StackGrid from "react-stack-grid"

const Vids = ({ size }) => {
  const data = useStaticQuery(graphql`
    {
      allMarkdownRemark(
        filter: { fields: { slug: { regex: "/video-/" } } }
        sort: { fields: frontmatter___order, order: ASC }
      ) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              vidId
              title
              description
              order
            }
          }
        }
      }
    }
  `)
  const grid = useRef(null)
  useEffect(() => {
    requestAnimationFrame(() => {
      if (grid.current) {
        grid.current.updateLayout()
      }
    })
  }, [grid])
  const allVids = data.allMarkdownRemark.edges
  return (
    <Article>
      <ArticleHeader title="Videos" />
      <StackGrid
        gridRef={r => (grid.current = r)}
        columnWidth={size.width <= 960 ? "100%" : "50%"}
        duration={0}
        gutterWidth={15}
        gutterHeight={10}
        appearDelay={60}
      >
        {allVids.map(vid => (
          <Video
            key={vid.node.fields.slug}
            videoSrcURL={vid.node.frontmatter.vidId}
            videoTitle={vid.node.frontmatter.title}
          />
        ))}
      </StackGrid>
    </Article>
  )
}

export default sizeMe()(Vids)
